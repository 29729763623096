"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubVideoMimeTypes = void 0;
var EHubVideoMimeTypes;
(function (EHubVideoMimeTypes) {
    EHubVideoMimeTypes["video/x-msvideo"] = "video/x-msvideo";
    EHubVideoMimeTypes["video/mp4"] = "video/mp4";
    EHubVideoMimeTypes["video/ogg"] = "video/ogg";
    EHubVideoMimeTypes["video/webm"] = "video/webm";
    EHubVideoMimeTypes["video/quicktime"] = "video/quicktime";
})(EHubVideoMimeTypes || (exports.EHubVideoMimeTypes = EHubVideoMimeTypes = {}));

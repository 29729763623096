"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubVoiceMimeTypes = void 0;
var EHubVoiceMimeTypes;
(function (EHubVoiceMimeTypes) {
    EHubVoiceMimeTypes["audio/mpeg"] = "audio/mpeg";
    EHubVoiceMimeTypes["audio/ogg"] = "audio/ogg";
    EHubVoiceMimeTypes["audio/wav"] = "audio/wav";
    EHubVoiceMimeTypes["audio/webm"] = "audio/webm";
    EHubVoiceMimeTypes["audio/x-wav"] = "audio/x-wav";
    EHubVoiceMimeTypes["audio/flac"] = "audio/flac";
    EHubVoiceMimeTypes["audio/annodex"] = "audio/annodex";
    EHubVoiceMimeTypes["audio/x-hx-aac-adts"] = "audio/x-hx-aac-adts";
})(EHubVoiceMimeTypes || (exports.EHubVoiceMimeTypes = EHubVoiceMimeTypes = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubFileType = void 0;
var EHubFileType;
(function (EHubFileType) {
    EHubFileType["VIDEO"] = "VIDEO";
    EHubFileType["PHOTO"] = "PHOTO";
    EHubFileType["AUDIO"] = "AUDIO";
    EHubFileType["VOICE"] = "VOICE";
    EHubFileType["DOCUMENT"] = "DOCUMENT";
})(EHubFileType || (exports.EHubFileType = EHubFileType = {}));

import * as i1 from '@angular/cdk/overlay';
import { OverlayModule, OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import * as i2 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Directive, Optional, Input, Output, Injectable, Component, ChangeDetectionStrategy, Inject, HostBinding, ViewChildren, HostListener, ElementRef, ViewEncapsulation, ContentChildren, NgModule } from '@angular/core';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
const _c0 = a0 => ({
  $implicit: a0
});
function ContextMenuContentComponent_ng_container_0_hr_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "hr", 5);
    i0.ɵɵlistener("mouseenter", function ContextMenuContentComponent_ng_container_0_hr_1_Template_hr_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.hideSubMenus());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const menuDirective_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("contextMenuContentItem", menuDirective_r3);
  }
}
function ContextMenuContentComponent_ng_container_0_button_2_ng_template_2_Template(rf, ctx) {}
function ContextMenuContentComponent_ng_container_0_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6, 0);
    i0.ɵɵlistener("click", function ContextMenuContentComponent_ng_container_0_button_2_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const buttonContextMenuContentItem_r5 = i0.ɵɵreference(1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onMenuItemSelect(buttonContextMenuContentItem_r5, $event));
    })("mouseenter", function ContextMenuContentComponent_ng_container_0_button_2_Template_button_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const buttonContextMenuContentItem_r5 = i0.ɵɵreference(1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      ctx_r1.hideSubMenus();
      return i0.ɵɵresetView(ctx_r1.openSubMenu(buttonContextMenuContentItem_r5.contextMenuContentItem == null ? null : buttonContextMenuContentItem_r5.contextMenuContentItem.subMenu, $event));
    });
    i0.ɵɵtemplate(2, ContextMenuContentComponent_ng_container_0_button_2_ng_template_2_Template, 0, 0, "ng-template", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const menuDirective_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("ngx-contextmenu--parent-menu", !!menuDirective_r3.subMenu);
    i0.ɵɵproperty("contextMenuContentItem", menuDirective_r3);
    i0.ɵɵattribute("aria-haspopup", !!menuDirective_r3.subMenu ? "menu" : null)("disabled", ctx_r1.isMenuItemDisabled(menuDirective_r3) ? "disabled" : null);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", menuDirective_r3.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(7, _c0, ctx_r1.value));
  }
}
function ContextMenuContentComponent_ng_container_0_span_3_ng_template_1_Template(rf, ctx) {}
function ContextMenuContentComponent_ng_container_0_span_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵlistener("click", function ContextMenuContentComponent_ng_container_0_span_3_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.stopEvent($event));
    })("contextmenu", function ContextMenuContentComponent_ng_container_0_span_3_Template_span_contextmenu_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.stopEvent($event));
    })("mouseenter", function ContextMenuContentComponent_ng_container_0_span_3_Template_span_mouseenter_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const menuDirective_r3 = i0.ɵɵnextContext().$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.isMenuItemDisabled(menuDirective_r3) ? null : ctx_r1.hideSubMenus());
    });
    i0.ɵɵtemplate(1, ContextMenuContentComponent_ng_container_0_span_3_ng_template_1_Template, 0, 0, "ng-template", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const menuDirective_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("disabled", ctx_r1.isMenuItemDisabled(menuDirective_r3));
    i0.ɵɵproperty("contextMenuContentItem", menuDirective_r3);
    i0.ɵɵattribute("aria-disabled", ctx_r1.isMenuItemDisabled(menuDirective_r3));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", menuDirective_r3.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(6, _c0, ctx_r1.value));
  }
}
function ContextMenuContentComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ContextMenuContentComponent_ng_container_0_hr_1_Template, 1, 1, "hr", 2)(2, ContextMenuContentComponent_ng_container_0_button_2_Template, 3, 9, "button", 3)(3, ContextMenuContentComponent_ng_container_0_span_3_Template, 2, 8, "span", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const menuDirective_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", menuDirective_r3.divider);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !menuDirective_r3.divider && !menuDirective_r3.passive);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !menuDirective_r3.divider && menuDirective_r3.passive);
  }
}
const evaluateIfFunction = (value, item) => {
  if (value instanceof Function) {
    return value(item);
  }
  return !!value;
};
class ContextMenuItemDirective {
  /**
   * Is this menu item disabled
   */
  set disabled(disabled) {
    this.#disabled = disabled;
  }
  get disabled() {
    return this.passive || this.divider || evaluateIfFunction(this.#disabled, this.value);
  }
  #disabled;
  constructor(template) {
    this.template = template;
    /**
     * True to make this menu item a divider
     */
    this.divider = false;
    /**
     * Is this menu item passive (for title)
     */
    this.passive = false;
    /**
     * Is this menu item visible
     */
    this.visible = true;
    /**
     * Emits event and item
     */
    this.execute = new EventEmitter();
    this.#disabled = false;
  }
  /**
   * @internal
   */
  triggerExecute(event, value) {
    if (evaluateIfFunction(this.#disabled, value)) {
      return;
    }
    this.execute.emit({
      event,
      value
    });
  }
  static {
    this.ɵfac = function ContextMenuItemDirective_Factory(t) {
      return new (t || ContextMenuItemDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ContextMenuItemDirective,
      selectors: [["", "contextMenuItem", ""]],
      inputs: {
        subMenu: "subMenu",
        divider: "divider",
        disabled: "disabled",
        passive: "passive",
        visible: "visible"
      },
      outputs: {
        execute: "execute"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuItemDirective, [{
    type: Directive,
    args: [{
      selector: '[contextMenuItem]'
    }]
  }], () => [{
    type: i0.TemplateRef,
    decorators: [{
      type: Optional
    }]
  }], {
    subMenu: [{
      type: Input
    }],
    divider: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    passive: [{
      type: Input
    }],
    visible: [{
      type: Input
    }],
    execute: [{
      type: Output
    }]
  });
})();
class ContextMenuContentItemDirective {
  get nativeElement() {
    return this.elementRef.nativeElement;
  }
  constructor(elementRef) {
    this.elementRef = elementRef;
  }
  /**
   * @implements FocusableOption
   */
  focus(origin) {
    this.elementRef.nativeElement.focus();
  }
  /**
   * @implements FocusableOption
   */
  get disabled() {
    return evaluateIfFunction(this.contextMenuContentItem?.disabled, this.contextMenuContentItem?.value);
  }
  static {
    this.ɵfac = function ContextMenuContentItemDirective_Factory(t) {
      return new (t || ContextMenuContentItemDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ContextMenuContentItemDirective,
      selectors: [["", "contextMenuContentItem", ""]],
      hostAttrs: [1, "ngx-context-menu-item"],
      inputs: {
        contextMenuContentItem: "contextMenuContentItem"
      },
      exportAs: ["contextMenuContentItem"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuContentItemDirective, [{
    type: Directive,
    args: [{
      selector: '[contextMenuContentItem]',
      exportAs: 'contextMenuContentItem',
      host: {
        class: 'ngx-context-menu-item'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    contextMenuContentItem: [{
      type: Input
    }]
  });
})();
class ContextMenuOverlaysService {
  constructor() {
    /**
     * Emits when all context menus are closed
     */
    this.allClosed = new EventEmitter();
    this.stack = [];
  }
  /**
   * Add an item to the stack
   */
  push(value) {
    this.stack.push(value);
  }
  /**
   * Clear the whole stack
   */
  closeAll() {
    this.stack.forEach(item => this.dispose(item));
    this.stack = [];
    this.allClosed.emit();
  }
  isEmpty() {
    return this.stack.length === 0;
  }
  dispose(overlayRef) {
    overlayRef.detach();
    overlayRef.dispose();
  }
  static {
    this.ɵfac = function ContextMenuOverlaysService_Factory(t) {
      return new (t || ContextMenuOverlaysService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContextMenuOverlaysService,
      factory: ContextMenuOverlaysService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuOverlaysService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * For testing purpose only
 */
const TESTING_WRAPPER = {
  FocusKeyManager
};
class ContextMenuContentComponent {
  // TODO: should be private but issue in spec with NullInjectorError: No provider for ElementRef!
  constructor(elementRef, document, contextMenuOverlaysService) {
    this.elementRef = elementRef;
    this.document = document;
    this.contextMenuOverlaysService = contextMenuOverlaysService;
    /**
     * The list of `IContextMenuItemDirective` that represent each menu items
     */
    this.menuDirectives = [];
    /**
     * A CSS class to apply a theme to the the menu
     */
    this.menuClass = '';
    /**
     * Emit when a menu item is selected
     */
    this.execute = new EventEmitter();
    /**
     * Emit when all menus is closed
     */
    this.close = new EventEmitter();
    /**
     * Accessibility
     *
     * @internal
     */
    this.role = 'menu';
    /**
     * Accessibility
     *
     * @internal
     */
    this.ariaOrientation = 'vertical';
    this.subscription = new Subscription();
  }
  /**
   * @internal
   */
  ngAfterViewInit() {
    this.setupDirectives();
    this.activeElement = this.document.activeElement;
    this.elementRef.nativeElement.focus();
  }
  /**
   * @internal
   */
  ngOnDestroy() {
    this.activeElement?.focus();
    this.subscription.unsubscribe();
    this.focusKeyManager?.destroy();
  }
  /**
   * @internal
   */
  onKeyArrowDownOrUp(event) {
    this.focusKeyManager?.onKeydown(event);
  }
  /**
   * @internal
   */
  onKeyArrowRight(event) {
    this.openCloseActiveItemSubMenu(this.dir !== 'rtl', event);
  }
  /**
   * @internal
   */
  onKeyArrowLeft(event) {
    this.openCloseActiveItemSubMenu(this.dir === 'rtl', event);
  }
  /**
   * @internal
   */
  onKeyEnterOrSpace(event) {
    if (!this.focusKeyManager?.activeItem) {
      return;
    }
    this.onMenuItemSelect(this.focusKeyManager.activeItem, event);
  }
  /**
   * @internal
   */
  onClickOrRightClick(event) {
    if (event.type === 'click' && event.button === 2) {
      return;
    }
    if (this.elementRef.nativeElement.contains(event.target)) {
      return;
    }
    this.contextMenuOverlaysService.closeAll();
  }
  /**
   * @internal
   */
  hideSubMenus() {
    this.menuDirectives.forEach(menuDirective => {
      menuDirective.subMenu?.hide();
    });
  }
  /**
   * @internal
   */
  stopEvent(event) {
    event.stopPropagation();
  }
  /**
   * @internal
   */
  isMenuItemDisabled(menuItem) {
    return evaluateIfFunction(menuItem.disabled, this.value);
  }
  /**
   * @internal
   */
  isMenuItemVisible(menuItem) {
    return evaluateIfFunction(menuItem.contextMenuContentItem?.visible, this.value);
  }
  /**
   * @internal
   */
  openSubMenu(subMenu, event) {
    if (!subMenu) {
      return;
    }
    if (this.focusKeyManager?.activeItemIndex === null) {
      return;
    }
    const anchorContextMenuContentItem = this.contextMenuContentItems.toArray()[this.focusKeyManager?.activeItemIndex ?? 0];
    const anchorElement = anchorContextMenuContentItem && anchorContextMenuContentItem.nativeElement;
    if (anchorElement && event instanceof KeyboardEvent) {
      subMenu.show({
        anchoredTo: 'element',
        anchorElement,
        value: this.value,
        parentContextMenu: this
      });
      return;
    }
    if (subMenu.isOpen) {
      return;
    }
    if (event.currentTarget) {
      subMenu.show({
        anchoredTo: 'element',
        anchorElement: event.currentTarget,
        value: this.value,
        parentContextMenu: this
      });
      return;
    }
    subMenu.show({
      anchoredTo: 'position',
      x: event.clientX,
      y: event.clientY,
      value: this.value
    });
  }
  /**
   * @internal
   */
  onMenuItemSelect(menuContentItem, event) {
    this.cancelEvent(event);
    this.openSubMenu(menuContentItem.contextMenuContentItem?.subMenu, event);
    if (!menuContentItem.contextMenuContentItem?.subMenu) {
      this.triggerExecute(menuContentItem, event);
    }
  }
  triggerExecute(menuItem, event) {
    menuItem.contextMenuContentItem?.triggerExecute(event, this.value);
  }
  setupDirectives() {
    this.menuDirectives.forEach(menuDirective => {
      menuDirective.value = this.value;
      this.subscription.add(menuDirective.execute.subscribe(event => {
        this.execute.emit({
          ...event,
          menuDirective
        });
      }));
    });
    this.focusKeyManager = new TESTING_WRAPPER.FocusKeyManager(this.contextMenuContentItems).withWrap();
  }
  openCloseActiveItemSubMenu(open, event) {
    if (open) {
      this.openActiveItemSubMenu(event);
      return;
    }
    this.closeActiveItemSubMenu(event);
  }
  openActiveItemSubMenu(event) {
    if (this.focusKeyManager?.activeItemIndex === null) {
      return;
    }
    this.cancelEvent(event);
    if (this.focusKeyManager?.activeItem) {
      this.openSubMenu(this.focusKeyManager.activeItem?.contextMenuContentItem?.subMenu, event);
    }
  }
  closeActiveItemSubMenu(event) {
    this.hideSubMenus();
    if (!this.focusKeyManager?.activeItemIndex) {
      return;
    }
    this.close.emit();
    this.cancelEvent(event);
  }
  cancelEvent(event) {
    if (!event || !event.target) {
      return;
    }
    const target = event.target;
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(target.tagName) || target.isContentEditable) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }
  static {
    this.ɵfac = function ContextMenuContentComponent_Factory(t) {
      return new (t || ContextMenuContentComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(ContextMenuOverlaysService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ContextMenuContentComponent,
      selectors: [["context-menu-content"]],
      viewQuery: function ContextMenuContentComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(ContextMenuContentItemDirective, 5, ContextMenuContentItemDirective);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contextMenuContentItems = _t);
        }
      },
      hostAttrs: ["tabindex", "0", "role", "dialog", 1, "ngx-contextmenu"],
      hostVars: 5,
      hostBindings: function ContextMenuContentComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.ArrowDown", function ContextMenuContentComponent_keydown_ArrowDown_HostBindingHandler($event) {
            return ctx.onKeyArrowDownOrUp($event);
          })("keydown.ArrowUp", function ContextMenuContentComponent_keydown_ArrowUp_HostBindingHandler($event) {
            return ctx.onKeyArrowDownOrUp($event);
          })("keydown.ArrowRight", function ContextMenuContentComponent_keydown_ArrowRight_HostBindingHandler($event) {
            return ctx.onKeyArrowRight($event);
          })("keydown.ArrowLeft", function ContextMenuContentComponent_keydown_ArrowLeft_HostBindingHandler($event) {
            return ctx.onKeyArrowLeft($event);
          })("keydown.Enter", function ContextMenuContentComponent_keydown_Enter_HostBindingHandler($event) {
            return ctx.onKeyEnterOrSpace($event);
          }, false, i0.ɵɵresolveWindow)("keydown.Space", function ContextMenuContentComponent_keydown_Space_HostBindingHandler($event) {
            return ctx.onKeyEnterOrSpace($event);
          }, false, i0.ɵɵresolveWindow)("click", function ContextMenuContentComponent_click_HostBindingHandler($event) {
            return ctx.onClickOrRightClick($event);
          }, false, i0.ɵɵresolveDocument);
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("aria-orientation", ctx.ariaOrientation);
          i0.ɵɵattribute("dir", ctx.dir)("role", ctx.role);
          i0.ɵɵclassMap(ctx.menuClass);
        }
      },
      inputs: {
        menuDirectives: "menuDirectives",
        value: "value",
        dir: "dir",
        parentContextMenu: "parentContextMenu",
        menuClass: "menuClass"
      },
      outputs: {
        execute: "execute",
        close: "close"
      },
      decls: 1,
      vars: 1,
      consts: [["buttonContextMenuContentItem", "contextMenuContentItem"], [4, "ngFor", "ngForOf"], ["role", "separator", 3, "contextMenuContentItem", "mouseenter", 4, "ngIf"], ["type", "button", "role", "menuitem", 3, "contextMenuContentItem", "ngx-contextmenu--parent-menu", "click", "mouseenter", 4, "ngIf"], ["class", "ngx-contextmenu-item--passive", "role", "menuitem", 3, "contextMenuContentItem", "disabled", "click", "contextmenu", "mouseenter", 4, "ngIf"], ["role", "separator", 3, "mouseenter", "contextMenuContentItem"], ["type", "button", "role", "menuitem", 3, "click", "mouseenter", "contextMenuContentItem"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], ["role", "menuitem", 1, "ngx-contextmenu-item--passive", 3, "click", "contextmenu", "mouseenter", "contextMenuContentItem"]],
      template: function ContextMenuContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ContextMenuContentComponent_ng_container_0_Template, 4, 3, "ng-container", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.menuDirectives);
        }
      },
      dependencies: [i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, ContextMenuContentItemDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuContentComponent, [{
    type: Component,
    args: [{
      selector: 'context-menu-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        tabindex: '0',
        role: 'dialog',
        class: 'ngx-contextmenu'
      },
      template: "<ng-container *ngFor=\"let menuDirective of menuDirectives; let i = index\">\n  <hr\n    [contextMenuContentItem]=\"menuDirective\"\n    *ngIf=\"menuDirective.divider\"\n    role=\"separator\"\n    (mouseenter)=\"hideSubMenus()\"\n  />\n  <button\n    #buttonContextMenuContentItem=\"contextMenuContentItem\"\n    [contextMenuContentItem]=\"menuDirective\"\n    *ngIf=\"!menuDirective.divider && !menuDirective.passive\"\n    type=\"button\"\n    role=\"menuitem\"\n    [attr.aria-haspopup]=\"!!menuDirective.subMenu ? 'menu' : null\"\n    [class.ngx-contextmenu--parent-menu]=\"!!menuDirective.subMenu\"\n    [attr.disabled]=\"isMenuItemDisabled(menuDirective) ? 'disabled' : null\"\n    (click)=\"onMenuItemSelect(buttonContextMenuContentItem, $event)\"\n    (mouseenter)=\"\n      hideSubMenus();\n      openSubMenu(\n        buttonContextMenuContentItem.contextMenuContentItem?.subMenu,\n        $event\n      )\n    \"\n  >\n    <ng-template\n      [ngTemplateOutlet]=\"menuDirective.template\"\n      [ngTemplateOutletContext]=\"{ $implicit: value }\"\n    ></ng-template>\n  </button>\n\n  <span\n    [contextMenuContentItem]=\"menuDirective\"\n    *ngIf=\"!menuDirective.divider && menuDirective.passive\"\n    class=\"ngx-contextmenu-item--passive\"\n    role=\"menuitem\"\n    [attr.aria-disabled]=\"isMenuItemDisabled(menuDirective)\"\n    (click)=\"stopEvent($event)\"\n    (contextmenu)=\"stopEvent($event)\"\n    [class.disabled]=\"isMenuItemDisabled(menuDirective)\"\n    (mouseenter)=\"isMenuItemDisabled(menuDirective) ? null : hideSubMenus()\"\n  >\n    <ng-template\n      [ngTemplateOutlet]=\"menuDirective.template\"\n      [ngTemplateOutletContext]=\"{ $implicit: value }\"\n    ></ng-template>\n  </span>\n</ng-container>\n"
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: ContextMenuOverlaysService
  }], {
    menuDirectives: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    dir: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.dir']
    }],
    parentContextMenu: [{
      type: Input
    }],
    menuClass: [{
      type: HostBinding,
      args: ['class']
    }, {
      type: Input
    }],
    execute: [{
      type: Output
    }],
    close: [{
      type: Output
    }],
    contextMenuContentItems: [{
      type: ViewChildren,
      args: [ContextMenuContentItemDirective, {
        read: ContextMenuContentItemDirective
      }]
    }],
    role: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    ariaOrientation: [{
      type: HostBinding,
      args: ['aria-orientation']
    }],
    onKeyArrowDownOrUp: [{
      type: HostListener,
      args: ['keydown.ArrowDown', ['$event']]
    }, {
      type: HostListener,
      args: ['keydown.ArrowUp', ['$event']]
    }],
    onKeyArrowRight: [{
      type: HostListener,
      args: ['keydown.ArrowRight', ['$event']]
    }],
    onKeyArrowLeft: [{
      type: HostListener,
      args: ['keydown.ArrowLeft', ['$event']]
    }],
    onKeyEnterOrSpace: [{
      type: HostListener,
      args: ['window:keydown.Enter', ['$event']]
    }, {
      type: HostListener,
      args: ['window:keydown.Space', ['$event']]
    }],
    onClickOrRightClick: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }]
  });
})();
const getPositionsToXY = (dir = 'ltr') => {
  if (dir === 'ltr') {
    return [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom'
    }, {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top'
    }, {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center'
    }, {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center'
    }];
  }
  return [{
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top'
  }, {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom'
  }, {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top'
  }, {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top'
  }, {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center'
  }, {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center'
  }];
};
const getPositionsToAnchorElement = (dir = 'ltr') => {
  if (dir === 'ltr') {
    return [{
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top'
    }, {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom'
    }, {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom'
    }];
  } else {
    return [{
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top'
    }, {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom'
    }, {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom'
    }];
  }
};
class ContextMenuComponent {
  /**
   * Returns true if the context menu is opened, false otherwise
   */
  get isOpen() {
    return this.#isOpen;
  }
  #isOpen;
  constructor(overlay, scrollStrategy, contextMenuOverlaysService) {
    this.overlay = overlay;
    this.scrollStrategy = scrollStrategy;
    this.contextMenuOverlaysService = contextMenuOverlaysService;
    /**
     * A CSS class to apply to the context menu overlay, ideal for theming and custom styling
     */
    this.menuClass = '';
    /**
     * Disable the whole context menu
     */
    this.disabled = false;
    /**
     * Emit when the menu is opened
     */
    this.open = new EventEmitter();
    /**
     * Emit when the menu is closed
     */
    this.close = new EventEmitter();
    /**
     * @internal
     */
    this.visibleMenuItems = [];
    this.subscriptions = new Subscription();
    this.#isOpen = false;
  }
  /**
   * @internal
   */
  ngOnInit() {
    const subscription = this.contextMenuOverlaysService.allClosed.subscribe(() => {
      this.#isOpen = false;
    });
    this.subscriptions.add(subscription);
  }
  /**
   * @internal
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  /**
   * @internal
   */
  show(event) {
    if (this.disabled) {
      return;
    }
    this.value = event.value;
    this.setVisibleMenuItems();
    this.openContextMenu({
      ...event,
      menuItemDirectives: this.visibleMenuItems,
      menuClass: this.menuClass,
      dir: this.dir
    });
    this.open.next(event);
  }
  /**
   * @internal
   */
  hide() {
    this.contextMenuContentComponent?.menuDirectives.forEach(menuDirective => {
      menuDirective.subMenu?.hide();
    });
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
    this.#isOpen = false;
  }
  /**
   * @internal
   */
  openContextMenu(context) {
    let positionStrategy;
    if (context.anchoredTo === 'position') {
      positionStrategy = this.overlay.position().flexibleConnectedTo({
        x: context.x,
        y: context.y
      }).withPositions(getPositionsToXY(context.dir));
    } else {
      const {
        anchorElement,
        parentContextMenu
      } = context;
      positionStrategy = this.overlay.position().flexibleConnectedTo(new ElementRef(anchorElement)).withPositions(getPositionsToAnchorElement(parentContextMenu.dir));
    }
    this.overlayRef = this.overlay.create({
      positionStrategy,
      panelClass: 'ngx-contextmenu-overlay',
      scrollStrategy: this.scrollStrategy.close()
    });
    this.contextMenuOverlaysService.push(this.overlayRef);
    this.attachContextMenu(context);
    this.#isOpen = true;
  }
  attachContextMenu(context) {
    const {
      value,
      menuItemDirectives
    } = context;
    const contextMenuContentRef = this.overlayRef?.attach(new ComponentPortal(ContextMenuContentComponent));
    const contextMenuContentComponent = contextMenuContentRef?.instance;
    if (!contextMenuContentComponent) {
      return;
    }
    this.contextMenuContentComponent = contextMenuContentComponent;
    contextMenuContentComponent.value = value;
    contextMenuContentComponent.menuDirectives = menuItemDirectives;
    contextMenuContentComponent.menuClass = this.getMenuClass(context);
    contextMenuContentComponent.dir = this.getDir(context);
    const closeSubscription = contextMenuContentComponent.close.subscribe(() => {
      this.overlayRef?.detach();
      this.overlayRef?.dispose();
    });
    const executeSubscription = contextMenuContentComponent.execute.subscribe(() => {
      this.contextMenuOverlaysService.closeAll();
    });
    contextMenuContentRef.onDestroy(() => {
      this.close.emit();
      closeSubscription.unsubscribe();
      executeSubscription.unsubscribe();
    });
    contextMenuContentRef.changeDetectorRef.detectChanges();
  }
  getMenuClass(event) {
    return event.menuClass || event.anchoredTo === 'element' && event?.parentContextMenu?.menuClass || '';
  }
  getDir(event) {
    return event.dir || event.anchoredTo === 'element' && event?.parentContextMenu?.dir || undefined;
  }
  isMenuItemVisible(menuItem) {
    return evaluateIfFunction(menuItem.visible, this.value);
  }
  setVisibleMenuItems() {
    this.visibleMenuItems = this.menuItems?.filter(menuItem => this.isMenuItemVisible(menuItem)) ?? [];
  }
  static {
    this.ɵfac = function ContextMenuComponent_Factory(t) {
      return new (t || ContextMenuComponent)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i1.ScrollStrategyOptions), i0.ɵɵdirectiveInject(ContextMenuOverlaysService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ContextMenuComponent,
      selectors: [["context-menu"]],
      contentQueries: function ContextMenuComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, ContextMenuItemDirective, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.menuItems = _t);
        }
      },
      inputs: {
        menuClass: "menuClass",
        disabled: "disabled",
        dir: "dir"
      },
      outputs: {
        open: "open",
        close: "close"
      },
      decls: 0,
      vars: 0,
      template: function ContextMenuComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuComponent, [{
    type: Component,
    args: [{
      encapsulation: ViewEncapsulation.None,
      selector: 'context-menu',
      template: ''
    }]
  }], () => [{
    type: i1.Overlay
  }, {
    type: i1.ScrollStrategyOptions
  }, {
    type: ContextMenuOverlaysService
  }], {
    menuClass: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    dir: [{
      type: Input
    }],
    open: [{
      type: Output
    }],
    close: [{
      type: Output
    }],
    menuItems: [{
      type: ContentChildren,
      args: [ContextMenuItemDirective]
    }]
  });
})();
class ContextMenuDirective {
  /**
   * Return true if the context menu is opened, false otherwise
   */
  get isOpen() {
    return this.contextMenu?.isOpen ?? false;
  }
  constructor(elementRef, contextMenuOverlaysService) {
    this.elementRef = elementRef;
    this.contextMenuOverlaysService = contextMenuOverlaysService;
    /**
     * The directive must have a tabindex for being accessible
     */
    this.tabindex = '0';
  }
  /**
   * @internal
   */
  onContextMenu(event) {
    if (!this.canOpen()) {
      return;
    }
    this.closeAll();
    this.contextMenu?.show({
      anchoredTo: 'position',
      x: event.clientX,
      y: event.clientY,
      value: this.contextMenuValue
    });
    event.preventDefault();
    event.stopPropagation();
  }
  /**
   * @internal
   */
  onKeyArrowEscape() {
    this.close();
  }
  /**
   * Programmatically open the context menu
   */
  open(event) {
    if (!this.canOpen()) {
      return;
    }
    if (event instanceof MouseEvent) {
      this.onContextMenu(event);
      return;
    }
    const {
      x,
      y,
      height
    } = this.elementRef.nativeElement.getBoundingClientRect();
    this.contextMenu?.show({
      anchoredTo: 'position',
      x,
      y: y + height,
      value: this.contextMenuValue
    });
  }
  /**
   * Programmatically close the context menu
   */
  close() {
    this.contextMenu?.hide();
  }
  closeAll() {
    this.contextMenuOverlaysService.closeAll();
  }
  canOpen() {
    return (this.contextMenu && !this.contextMenu.disabled) ?? false;
  }
  static {
    this.ɵfac = function ContextMenuDirective_Factory(t) {
      return new (t || ContextMenuDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ContextMenuOverlaysService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ContextMenuDirective,
      selectors: [["", "contextMenu", ""]],
      hostAttrs: ["role", "button", "attr.aria-haspopup", "menu"],
      hostVars: 2,
      hostBindings: function ContextMenuDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("contextmenu", function ContextMenuDirective_contextmenu_HostBindingHandler($event) {
            return ctx.onContextMenu($event);
          })("contextmenu", function ContextMenuDirective_contextmenu_HostBindingHandler() {
            return ctx.onKeyArrowEscape();
          }, false, i0.ɵɵresolveWindow)("keydown.Escape", function ContextMenuDirective_keydown_Escape_HostBindingHandler() {
            return ctx.onKeyArrowEscape();
          }, false, i0.ɵɵresolveWindow);
        }
        if (rf & 2) {
          i0.ɵɵattribute("tabindex", ctx.tabindex)("aria-expanded", ctx.isOpen);
        }
      },
      inputs: {
        contextMenuValue: "contextMenuValue",
        contextMenu: "contextMenu",
        tabindex: "tabindex"
      },
      exportAs: ["ngxContextMenu"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuDirective, [{
    type: Directive,
    args: [{
      selector: '[contextMenu]',
      exportAs: 'ngxContextMenu',
      host: {
        role: 'button',
        'attr.aria-haspopup': 'menu'
      }
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: ContextMenuOverlaysService
  }], {
    contextMenuValue: [{
      type: Input
    }],
    contextMenu: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.tabindex']
    }],
    isOpen: [{
      type: HostBinding,
      args: ['attr.aria-expanded']
    }],
    onContextMenu: [{
      type: HostListener,
      args: ['contextmenu', ['$event']]
    }],
    onKeyArrowEscape: [{
      type: HostListener,
      args: ['window:contextmenu']
    }, {
      type: HostListener,
      args: ['window:keydown.Escape']
    }]
  });
})();
class ContextMenuModule {
  static {
    this.ɵfac = function ContextMenuModule_Factory(t) {
      return new (t || ContextMenuModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ContextMenuModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: OverlayContainer,
        useClass: FullscreenOverlayContainer
      }],
      imports: [CommonModule, OverlayModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuModule, [{
    type: NgModule,
    args: [{
      declarations: [ContextMenuComponent, ContextMenuContentComponent, ContextMenuContentItemDirective, ContextMenuDirective, ContextMenuItemDirective],
      providers: [{
        provide: OverlayContainer,
        useClass: FullscreenOverlayContainer
      }],
      exports: [ContextMenuDirective, ContextMenuComponent, ContextMenuItemDirective],
      imports: [CommonModule, OverlayModule]
    }]
  }], null, null);
})();

/**
 * Programmatically open a ContextMenuComponent to a X/Y position
 */
class ContextMenuService {
  constructor(contextMenuOverlaysService) {
    this.contextMenuOverlaysService = contextMenuOverlaysService;
  }
  /**
   * Show the given `ContextMenuComponent` at a specified X/Y position
   */
  show(contextMenu, options = {
    x: 0,
    y: 0
  }) {
    contextMenu.show({
      anchoredTo: 'position',
      value: options.value,
      x: options.x,
      y: options.y
    });
  }
  /**
   * Close all open `ContextMenuComponent`
   */
  closeAll() {
    this.contextMenuOverlaysService.closeAll();
  }
  /**
   * Return true if any `ContextMenuComponent` is open
   */
  hasOpenMenu() {
    return !this.contextMenuOverlaysService.isEmpty();
  }
  static {
    this.ɵfac = function ContextMenuService_Factory(t) {
      return new (t || ContextMenuService)(i0.ɵɵinject(ContextMenuOverlaysService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContextMenuService,
      factory: ContextMenuService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContextMenuService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ContextMenuOverlaysService
  }], null);
})();

/*
 * Public API Surface of @perfectmemory/ngx-contextmenu
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ContextMenuComponent, ContextMenuDirective, ContextMenuItemDirective, ContextMenuModule, ContextMenuService };

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubAudioMimeTypes = void 0;
var EHubAudioMimeTypes;
(function (EHubAudioMimeTypes) {
    EHubAudioMimeTypes["audio/mpeg"] = "audio/mpeg";
    EHubAudioMimeTypes["audio/ogg"] = "audio/ogg";
    EHubAudioMimeTypes["audio/wav"] = "audio/wav";
    EHubAudioMimeTypes["audio/webm"] = "audio/webm";
    EHubAudioMimeTypes["audio/x-wav"] = "audio/x-wav";
    EHubAudioMimeTypes["audio/flac"] = "audio/flac";
    EHubAudioMimeTypes["audio/annodex"] = "audio/annodex";
    EHubAudioMimeTypes["audio/x-hx-aac-adts"] = "audio/x-hx-aac-adts";
})(EHubAudioMimeTypes || (exports.EHubAudioMimeTypes = EHubAudioMimeTypes = {}));
